export default {
  shop_center_txt: 'ศูนย์การค้า',
  score_txt: 'คะแนน',
  minute_txt: 'นาที',
  wallet_txt: 'กระเป๋าสตางค์',
  today_txt: 'วันนี้',
  week_txt: 'สัปดาห์นี้',
  month_txt: 'เดือนนี้',
  year_txt: 'ทั้งปี',
  main_indicators_txt: 'ตัวชี้วัดหลัก',
  sale_price: 'ราคาขาย',
  expected_profit: 'กำไรที่คาดหวัง',
  order_count: 'ปริมาณการสั่งซื้อ',
  goods_click_count: 'จำนวนการคลิกผลิตภัณฑ์',
  supplier_txt: 'ซัพพลายเออร์',
  friends_help_txt: 'เพื่อนช่วย',
  feedback_txt: 'คำติชม',
  feedback: 'ข้อเสนอแนะ',
  online_service_txt_1: 'บริการลูกค้าเงินฝาก',
  online_service_txt_2: 'การให้คำปรึกษาทางธุรกิจ',
  q_and_a_txt: 'คำถามและคำตอบ',
  open_now_txt: 'เปิด',
  time_txt: 'เวลา',
  times_txt: 'เวลา',

  wallet_total_assets: 'สินทรัพย์ทั้งหมด',
  balance_txt: 'สมดุล',
  pending_settlement_txt: 'รอการชำระหนี้',
  recharge_txt: 'เติมเงิน',
  withdraw_txt: 'ถอนออก',
  fund_details_txt: 'รายละเอียดกองทุน',
  no_more_txt: 'ไม่มีอีกแล้ว',
  no_data_txt: 'ยังไม่มีข้อมูล',
  detail_txt: 'รายละเอียด',
  num_txt: 'หมายเลข',
  money_txt: 'จำนวนเงิน',
  remark_txt: 'หมายเหตุ',
  no_remark_txt: 'ไม่มีหมายเหตุ',
  tip_txt: 'เคล็ดลับ',
  tip_content_txt:
    'เนื่องจากปัญหาด้านสกุลเงินที่จัดทำโดยผู้ขายในประเทศต่างๆ ในปัจจุบัน',
  need_contact_service: 'จำเป็นต้องติดต่อฝ่ายบริการลูกค้า',
  contact_service: 'ติดต่อฝ่ายบริการลูกค้า',

  feedback_title: 'ตอบกลับความคิดเห็นของคุณ',
  feedback_placeholder: 'ด้วยเหตุผลด้านความปลอดภัย โปรดอย่าใส่ข้อมูลส่วนบุคคล',
  submit_txt: 'ส่ง',
  sure_txt: 'ตกลง',
  submit_suc_txt: 'ส่งสำเร็จ',

  loading: 'กำลังโหลด...',

  newer_action: 'กิจกรรมของผู้มาใหม่',
  newer_traffic_package: 'แพ็คเกจการรับส่งข้อมูลการสนับสนุนผู้มาใหม่',
  newer_get_millions_of_products: 'รับผลิตภัณฑ์นับล้าน',
  newer_get: 'ได้รับ',
  newer_receive: 'รับ',
  newer_action_ruler: 'กฎกิจกรรมของผู้มาใหม่',
  newer_receive_suc: 'ได้รับเรียบร้อยแล้ว',

  help_friends_help: 'เพื่อนช่วย',
  help_your_friends_stores: 'ช่วยเหลือเพื่อน',
  get_store_product_heating: 'รับการทำความร้อนผลิตภัณฑ์จากร้านค้า',
  help_friends_txt: 'ช่วยเหลือเพื่อน',
  help_friends_activity_rules: 'กฎกิจกรรม',
  assistance_details: 'รายละเอียดความช่วยเหลือ',
  help_friend_name: 'ช่วยชื่อเพื่อน',
  help_friend_invite_code: 'ช่วยเหลือรหัสเชิญเพื่อน',
  mine_invite_code: 'รหัสคำเชิญของฉัน',
  plz_input: 'กรุณาระบุ',
  plz_input_friend_name: 'โปรดป้อนชื่อเพื่อนของคุณเพื่อช่วยเหลือ',
  help_suc: 'ช่วยให้ประสบความสำเร็จ',

  online_service: 'บริการลูกค้าออนไลน์',
  online_24_hour_service: 'สนับสนุนออนไลน์ตลอด 24 ชั่วโมง',
  customer_service: 'บริการลูกค้า',
  copy_and_add_id: 'คัดลอกและเพิ่ม LINE ID',
  plz_input_question_detail: 'กรุณากรอกรายละเอียดคำถามของคุณ',
  harry_reply_your: 'เราจะตอบกลับคุณโดยเร็วที่สุด',
  click_copy: 'คลิกเพื่อคัดลอก',
  copy_suc: 'คัดลอกสำเร็จ',
  copy_fail: 'การคัดลอกล้มเหลว',

  overall: 'ทั้งหมด',
  cooperate: 'ร่วมมือ',
  cancel: 'ยกเลิก',
  supplier_num: 'หมายเลขซัพพลายเออร์',
  no_use_supplier: 'ไม่มีซัพพลายเออร์ที่มีอยู่',
  here_add_supplier: 'เพิ่มซัพพลายเออร์ใหม่ที่นี่',
  add_supplier: 'เพิ่มซัพพลายเออร์',
  plz_input_supplier_num: 'โปรดป้อนหมายเลขซัพพลายเออร์',
  add_suc: 'เพิ่มเรียบร้อยแล้ว',

  new_products_launched: 'เปิดตัวผลิตภัณฑ์ใหม่',
  profit_txt: 'กำไร',
  has_been_added: 'อยู่บนชั้นวางแล้ว',
  on_display: 'สามารถวางบนชั้นวางได้',
  coming_soon_txt: 'วางจำหน่ายแล้ว',
  sold_txt: 'ขายแล้ว',
  removed_txt: 'ลบแล้ว',

  order_detail_txt: 'รายละเอียดการสั่งซื้อ',
  waiting_express_delivery: 'กำลังรอการจัดส่งด่วน',
  customer_info: 'ข้อมูลลูกค้า',
  order_good: 'สั่งซื้อสินค้า',
  order_num: 'หมายเลขคำสั่งซื้อ',
  order_date: 'วันที่สั่งซื้อ',
  order_content: 'สรุปคำสั่งซื้อ',
  purchase_price_txt: 'ราคาซื้อ',
  income_txt: 'รายได้',

  order_txt: 'สั่งซื้อ',
  pending_payment: 'รอดำเนินการชำระเงิน',
  ready_for_shipment: 'กำลังจะถูกจัดส่ง',
  shipped_txt: 'จัดส่งแล้ว',
  paid_txt: 'จัดส่งแล้ว',
  good_num: 'หมายเลขรายการ',
  pay_and_ship: 'การชำระเงินและการจัดส่ง',
  pay_suc: 'การชำระเงินสำเร็จ',
  no_order: 'ยังไม่มีคำสั่งซื้อ',
  no_order_tips: 'คุณสามารถติดตามคำสั่งซื้อและการคืนสินค้าได้ที่นี่',
  suer_order: 'ยืนยันการชำระเงิน',
  pay_way: 'วิธีการชำระเงิน',
  wallet_pay: 'การชำระเงินด้วยกระเป๋าเงิน',
  sure_pay: 'ยืนยันการชำระเงิน',
  paying_txt: 'กำลังชำระเงิน...',

  manage_showcase: 'จัดการตู้โชว์',
  add_new_good: 'เพิ่มผลิตภัณฑ์ใหม่',
  heating_txt: 'เครื่องทำความร้อน',
  promotion: 'โปรโมชั่น',
  promotion_tip: 'เคล็ดลับการส่งเสริมการขาย',
  promotion_tip_txt:
    'คะแนนปัจจุบันของคุณน้อยกว่า 200 และคุณไม่สามารถชำระค่าผลิตภัณฑ์ของคุณได้',
  copy_link: 'คัดลอกลิงก์',
  scan_qr_code: 'สแกนโค้ด QR',

  about_good: 'เกี่ยวกับผลิตภัณฑ์',
  specification: 'ข้อกำหนด',
  product_overview: 'ภาพรวมผลิตภัณฑ์',

  top_tips_1: 'พลิกโฉมร้านค้า Douyin ออนไลน์',
  top_tips_2: 'ปัจจุบันรองรับเฉพาะคำเชิญภายในเท่านั้น',

  certification_activation_code: 'รหัสคำเชิญ',
  plz_write_invite_code: 'กรุณากรอกรหัสเชิญ',
  active_user_email: 'อีเมล',
  plz_input_active_user_email: 'กรุณากรอกอีเมลของคุณ',
  active_user_email_code: 'รหัสยืนยันอีเมล',
  plz_input_active_user_email_code: 'กรุณากรอกรหัสยืนยันอีเมล',
  active_get_email_code_txt: 'รับรหัสยืนยัน',
  active_user_name: 'ชื่อ',
  plz_input_active_user_name: 'กรุณากรอกชื่อของคุณ',
  active_user_id: 'หมายเลขบัตรประจำตัวประชาชน/หนังสือเดินทาง',
  plz_input_active_user_id:
    'กรุณากรอกหมายเลขบัตรประจำตัวประชาชน/หนังสือเดินทางของคุณ',
  upload_active_user_id: 'การอัปโหลดใบรับรอง',
  plz_upload_user_id_img:
    'โปรดอัปโหลดภาพถ่ายด้านหน้าบัตรประจำตัวประชาชน/หน้าหนังสือเดินทางของคุณ',

  promotion_01: 'โปรโมชั่น',
  promotion_02: 'จำนวนโปรโมชั่น',
  promotion_03: 'กรุณากรอกจำนวนโปรโมชั่น',
  promotion_04: 'ยืนยันการส่งเสริมการขาย',
  promotion_05: 'การโปรโมตสำเร็จ',

  bao_txt: 'อัตราการสัมผัส',
  bal_no: 'ยอดคงเหลือไม่เพียงพอ',

  _yqm_01: 'กรุณากรอกรหัสเปิดใช้งานที่ถูกต้อง',

  all_orders: 'คำสั่งซื้อทั้งหมด',
  mine_orders: 'คำสั่งซื้อของฉัน',
  mine_services: 'บริการของฉัน',

  _in_sp: 'การจัดอันดับผลิตภัณฑ์',
  _r_k01: 'การจัดอันดับ',
  _r_k02: 'สินค้าโภคภัณฑ์',
  _r_k03: 'ความร้อน',

  _c_201: 'ไม่มีชื่อโดเมนการรับรองความถูกต้อง',
  _c_202: 'ผู้ใช้ได้รับการรับรองความถูกต้องและเปิดใช้งานแล้ว',
  _c_204: 'รหัสเปิดใช้งานถูกใช้แล้ว',

  gl_visits: 'การเข้าชม',

  hl_agreement: 'เห็นด้วย',
  hl_service_agreement: 'ข้อตกลงการบริการ',
  hl_selected_agreement: 'โปรดตรวจสอบข้อตกลงการบริการ',

  service_agreement_content_1:
    '1. เลือกประเทศและภูมิภาคที่เหมาะสม: ขั้นแรก คุณต้องกำหนดประเทศหรือภูมิภาคที่คุณต้องการเปิดร้าน Douyin สิ่งนี้ต้องการให้คุณมีความเข้าใจและการวิเคราะห์ตลาดผู้บริโภคของภูมิภาค ภูมิหลังทางวัฒนธรรม และการแข่งขัน เพื่อเป็นพื้นฐานสำหรับการวางตำแหน่งร้านค้าและกลยุทธ์ทางธุรกิจของคุณ ',
  service_agreement_content_2:
    '2. ลงทะเบียนบัญชี Douyin: หลังจากกำหนดประเทศหรือภูมิภาคเป้าหมายแล้ว คุณจะต้องลงทะเบียนบัญชี Douyin ที่เป็นของภูมิภาคนั้น คุณสามารถลงทะเบียนผ่านช่องทางอย่างเป็นทางการหรือค้นหาคนกลางในพื้นที่เพื่อช่วยดำเนินการลงทะเบียนให้เสร็จสิ้น ',
  service_agreement_content_3:
    '3. เตรียมข้อมูล: เมื่อลงทะเบียนบัญชี คุณต้องเตรียมข้อมูลที่เกี่ยวข้อง เอกสารรับรองข้อมูลบัตรประจำตัวส่วนบุคคลสามารถช่วยให้แพลตฟอร์ม Douyin สามารถยืนยันตัวตนที่ถูกต้องตามกฎหมายของคุณ เงินมัดจำเปิดร้าน 500 ดอลลาร์ และรับรองว่าร้านค้าของคุณปฏิบัติตามข้อกำหนดที่เกี่ยวข้อง กฎหมาย ข้อกำหนดด้านกฎระเบียบ ',
  service_agreement_content_4:
    '4. เลือกรูปแบบธุรกิจ: เมื่อเปิดร้าน Douyin คุณสามารถเลือกรูปแบบที่ดำเนินการเองหรือรูปแบบการเข้าสู่บุคคลที่สามได้ โมเดลที่ดำเนินการด้วยตนเองหมายความว่าผู้ค้าดำเนินการและจัดการร้านค้าโดยตรง ในขณะที่โมเดลรายการบุคคลที่สามหมายความว่าร้านค้าถูกส่งมอบให้กับแพลตฟอร์มของบุคคลที่สามเพื่อดำเนินการ ขึ้นอยู่กับสถานการณ์จริงและข้อได้เปรียบด้านทรัพยากรของคุณ คุณสามารถเลือกรูปแบบธุรกิจที่เหมาะสมกับคุณที่สุดได้ ',
  service_agreement_content_5:
    '5. การเตรียมผลิตภัณฑ์และการส่งเสริมการขาย: ก่อนที่จะเปิดร้านคุณต้องเตรียมสินค้าคงคลังให้เพียงพอและทำการตลาดอย่างมีประสิทธิภาพ คุณสามารถโปรโมตผ่านวิธีการออนไลน์และออฟไลน์ เช่น การซื้อแพ็คเกจการเข้าชมเพื่อเพิ่มการมองเห็นร้านค้าของคุณและดึงดูดผู้ใช้ให้สนใจและซื้อมากขึ้น ',
  service_agreement_content_6:
    '6. สร้างระบบการบริการลูกค้า: เพื่อมอบประสบการณ์การใช้งานที่ดีขึ้น คุณต้องสร้างระบบการบริการลูกค้าที่สมบูรณ์ รวมถึงการตอบคำถามและข้อร้องเรียนของผู้ใช้ทันที การจัดการปัญหาหลังการขาย ฯลฯ สิ่งนี้สามารถเพิ่มความไว้วางใจของผู้ใช้ในร้านค้าของคุณ และเพิ่มความพึงพอใจและความภักดีของลูกค้า ',
}
