export default {
  shop_center_txt: '쇼핑 센터',
  score_txt: '포인트',
  minute_txt: '분',
  wallet_txt: '지갑',
  today_txt: '오늘',
  week_txt: '이번 주',
  month_txt: '이번 달',
  year_txt: '연간',
  main_indicators_txt: '주요 지표',
  sale_price: '판매 가격',
  expected_profit: '기대 이익',
  order_count: '주문 수량',
  goods_click_count: '제품 클릭수',
  supplier_txt: '공급업체',
  friends_help_txt: '친구의 도움',
  feedback_txt: '피드백',
  feedback: '피드백',
  online_service_txt_1: '고객 서비스 입금',
  online_service_txt_2: '비즈니스 상담',
  q_and_a_txt: '질문과 답변',
  open_now_txt: '열기',
  certification_activation_code: '활성화 인증 코드',
  plz_write_invite_code: '초대코드를 입력해주세요',
  time_txt: '시간',
  times_txt: '시간',

  wallet_total_assets: '총 자산',
  balance_txt: '균형',
  pending_settlement_txt: '결제 대기 중',
  recharge_txt: '충전',
  withdraw_txt: '철회',
  fund_details_txt: '펀드 세부정보',
  no_more_txt: '더 이상',
  no_data_txt: '아직 데이터가 없습니다',
  detail_txt: '세부정보',
  num_txt: '숫자',
  money_txt: '금액',
  remark_txt: '비고',
  no_remark_txt: '설명 없음',
  tip_txt: '팁',
  tip_content_txt: '현재 다양한 국가의 판매자가 인수한 통화 문제로 인해',
  need_contact_service: '고객 서비스에 연락해야 합니다',
  contact_service: '고객 서비스에 문의',

  feedback_title: '당신의 의견을 피드백하세요',
  feedback_placeholder: '보안상의 이유로 개인정보를 포함하지 마세요.',
  submit_txt: '제출',
  sure_txt: '알았어',
  submit_suc_txt: '제출 성공',

  loading: '로드 중...',

  newer_action: '신규 활동',
  newer_traffic_package: '신입 지원 트래픽 패키지',
  newer_get_millions_of_products: '수백만 개의 제품 받기',
  newer_get: '접수됨',
  newer_receive: '수신',
  newer_action_ruler: '신규 활동 규칙',
  newer_receive_suc: '성공적으로 수신되었습니다',

  help_friends_help: '친구의 도움',
  help_your_friends_stores: '친구의 상점을 도와주세요',
  get_store_product_heating: '매장 제품 가열 가져오기',
  help_friends_txt: '친구를 도와주세요',
  help_friends_activity_rules: '친구 활동 규칙 도움말',
  assistance_details: '지원 세부정보',
  help_friend_name: '도움말 친구 이름',
  plz_input: '입력하세요',
  plz_input_friend_name: '도움을 줄 친구의 이름을 입력하세요',
  help_suc: '도움말 성공',

  online_service: '온라인 고객 서비스',
  online_24_hour_service: '24시간 온라인 지원',
  customer_service: '고객 서비스',
  copy_and_add_id: 'LINE ID 복사 및 추가',
  plz_input_question_detail: '질문의 세부정보를 입력하세요',
  harry_reply_your: '최대한 빨리 답변해 드리겠습니다.',
  click_copy: '복사하려면 클릭하세요',
  copy_suc: '복사 성공',
  copy_fail: '복사 실패',

  overall: '전체적으로',
  cooperate: '협력하다',
  cancel: '취소',
  supplier_num: '공급업체 번호',
  no_use_supplier: '사용 가능한 공급업체 없음',
  here_add_supplier: '여기에 새 공급업체 추가',
  add_supplier: '공급업체 추가',
  plz_input_supplier_num: '공급업체 번호를 입력하세요',
  add_suc: '추가 성공',

  new_products_launched: '신제품 출시',
  profit_txt: '이익',
  has_been_added: '이미 선반에 있습니다',
  on_display: '디스플레이',
  coming_soon_txt: '지금 이용 가능',
  sold_txt: '판매됨',
  removed_txt: '제거됨',

  order_detail_txt: '주문 세부정보',
  waiting_express_delivery: '빠른 배송을 기다리는 중',
  customer_info: '고객 정보',
  order_good: '상품 주문',
  order_num: '주문 번호',
  order_date: '주문 날짜',
  order_content: '주문 요약',
  purchase_price_txt: '구매 가격',
  income_txt: '소득',

  order_txt: '주문',
  pending_payment: '지불 보류 중',
  ready_for_shipment: '배송 준비 완료',
  shipped_txt: '배송됨',
  paid_txt: '배송됨',
  good_num: '항목 번호',
  pay_and_ship: '결제 및 배송',
  pay_suc: '결제 성공',
  no_order: '아직 주문이 없습니다',
  no_order_tips: '여기에서 주문 및 반품을 추적할 수 있습니다',
  suer_order: '결제 확인',
  pay_way: '결제 수단',
  wallet_pay: '지갑 결제',
  sure_pay: '결제 확인',
  paying_txt: '결제 중...',

  manage_showcase: '쇼케이스 관리',
  add_new_good: '새 제품 추가',
  heating_txt: '난방',
  promotion: '프로모션',
  promotion_tip: '프로모션 팁',
  promotion_tip_txt: '현재 포인트가 200 미만이므로 제품 결제가 불가능합니다.',
  copy_link: '링크 복사',
  scan_qr_code: 'QR 코드 스캔',

  about_good: '제품 정보',
  specification: '사양',
  product_overview: '제품 개요',

  top_tips_1: 'Douyin 매장을 온라인으로 전환하세요',
  top_tips_2: '현재 내부 초대만 지원합니다',
  active_place_holeder: '입력하세요',

  promotion_01: '프로모션',
  promotion_02: '프로모션 금액',
  promotion_03: '프로모션 금액을 입력해주세요',
  promotion_04: '프로모션 확인',
  promotion_05: '프로모션 성공',

  bao_txt: '노출',
  bal_no: '잔액 부족',

  _yqm_01: '올바른 활성화 코드를 입력하세요',

  all_orders: '모든 주문',
  mine_orders: '내 주문',
  mine_services: '내 서비스',

  _in_sp: '상품 순위',
  _r_k01: '순위',
  _r_k02: '상품',
  _r_k03: '열',

  _c_201: '인증 도메인 이름이 존재하지 않습니다',
  _c_202: '사용자가 인증 및 활성화되었습니다',
  _c_204: '활성화 코드가 사용되었습니다',

  gl_visits: '방문',

  hl_agreement: '동의함',
  hl_service_agreement: '서비스 계약',
  hl_selected_agreement: '서비스 계약을 확인하세요',

  service_agreement_content_1:
    '1. 적합한 국가 및 지역 선택: 먼저 Douyin 매장을 열고 싶은 특정 국가 또는 지역을 결정해야 합니다. 이를 위해서는 매장 포지셔닝 및 비즈니스 전략의 기초를 제공하기 위해 해당 지역의 소비자 시장, 문화적 배경 및 경쟁에 대한 특정 이해와 분석이 필요합니다. ',
  service_agreement_content_2:
    '2. Douyin 계정 등록: 대상 국가 또는 지역을 결정한 후 해당 지역에 속하는 Douyin 계정을 등록해야 합니다. 공식 채널을 통해 등록할 수도 있고, 등록 절차를 완료하는 데 도움을 줄 현지 중개인을 찾을 수도 있습니다. ',
  service_agreement_content_3:
    '3. 정보 준비: 계정 등록 시 관련 정보를 준비해야 하며, 개인 ID 카드 정보 인증 문서를 통해 Douyin 플랫폼에서 귀하의 적법한 신원을 확인하고, 개점 보증금 500달러를 확인하고, 귀하의 상점이 관련 규정을 준수하는지 확인할 수 있습니다. 법률, 규제 요구 사항. ',
  service_agreement_content_4:
    '4. 비즈니스 모델 선택: Douyin 매장 개설 시 자체 운영 모델 또는 타사 진입 모델을 선택할 수 있습니다. 자체 운영 모델은 매장 자체를 가맹점이 직접 운영, 관리하는 것을 의미하고, 제3자 진입 모델은 매장을 제3자 플랫폼에 넘겨 운영하는 것을 의미한다. 귀하의 실제 상황과 자원 이점을 바탕으로 귀하에게 가장 적합한 비즈니스 모델을 선택할 수 있습니다. ',
  service_agreement_content_5:
    '5. 상품 준비 및 판촉 : 매장 오픈 전 충분한 상품 재고를 준비하고 효과적인 마케팅을 실시해야 합니다. 트래픽 패키지 구매 등 온라인, 오프라인 방법을 통해 홍보할 수 있어 매장의 노출을 높이고 더 많은 사용자의 관심과 구매를 유도할 수 있습니다. ',
  service_agreement_content_6:
    '6. 고객 서비스 시스템 구축: 더 나은 사용자 경험을 제공하기 위해서는 완전한 고객 서비스 시스템을 구축해야 합니다. 이용자 문의 및 불만사항에 대한 신속한 답변, 사후처리 등을 포함합니다. 이를 통해 매장에 대한 사용자의 신뢰를 높이고 고객 만족도와 충성도를 높일 수 있습니다. ',
}
