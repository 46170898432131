export default {
  shop_center_txt: '商店中心',
  score_txt: '積分',
  minute_txt: '分',
  wallet_txt: '錢包',
  today_txt: '本日',
  week_txt: '本週',
  month_txt: '本月',
  year_txt: '整年',
  main_indicators_txt: '主要指標',
  sale_price: '銷售價格',
  expected_profit: '預期利潤',
  order_count: '訂單數量',
  goods_click_count: '產品點數',
  supplier_txt: '供應商',
  friends_help_txt: '朋友助力',
  feedback_txt: '回饋',
  feedback: '意見回饋',
  online_service_txt_1: '入金客服',
  online_service_txt_2: '業務諮詢',
  q_and_a_txt: '問答',
  open_now_txt: '開啟',
  certification_activation_code: '認證啟動碼',
  plz_write_invite_code: '請填入邀請碼',
  time_txt: '時',
  times_txt: '時間',

  wallet_total_assets: '總財產',
  balance_txt: '餘額',
  pending_settlement_txt: '待結算',
  recharge_txt: '充值',
  withdraw_txt: '提現',
  fund_details_txt: '資金明細',
  no_more_txt: '沒有更多了',
  no_data_txt: '暫無資料',
  detail_txt: '明細詳情',
  num_txt: '編號',
  money_txt: '金額',
  remark_txt: '備註',
  no_remark_txt: '無備註',
  tip_txt: '提示',
  tip_content_txt: '由於各國商家承銷幣種問題，目前',
  need_contact_service: '需聯絡客服',
  contact_service: '聯絡客服',

  feedback_title: '回饋你的意見',
  feedback_placeholder: '出於安全考慮，請勿包含個人資訊',
  submit_txt: '提交',
  sure_txt: '確定',
  submit_suc_txt: '提交成功',

  loading: '載入中...',

  newer_action: '新人活動',
  newer_traffic_package: '新人扶持流量包',
  newer_get_millions_of_products: '領取百萬商品',
  newer_get: '收到',
  newer_receive: '領取',
  newer_action_ruler: '新人活動規則',
  newer_receive_suc: '領取成功',

  help_friends_help: '好友助力',
  help_your_friends_stores: '幫助你的好友店家',
  get_store_product_heating: '取得商店商品加熱',
  help_friends_txt: '助力朋友',
  help_friends_activity_rules: '助力好友活動規則',
  assistance_details: '助力明細',
  help_friend_name: '助力好友名',
  plz_input: '請輸入',
  plz_input_friend_name: '請輸入助力好友名',
  help_suc: '助力成功',

  online_service: '線上客服',
  online_24_hour_service: '24小時線上支援',
  customer_service: '客戶服務',
  copy_and_add_id: '複製並新增LINE ID',
  plz_input_question_detail: '請輸入你的問題的詳細資料',
  harry_reply_your: '我們會盡快回覆你',
  click_copy: '點選複製',
  copy_suc: '複製成功',
  copy_fail: '複製失敗',

  overall: '整體',
  cooperate: '合作',
  cancel: '取消',
  supplier_num: '供貨商編號',
  no_use_supplier: '沒有可用的供應商',
  here_add_supplier: '在這裡新增供應商',
  add_supplier: '新增供貨商',
  plz_input_supplier_num: '請輸入供貨商編號',
  add_suc: '新增成功',

  new_products_launched: '上架新商品',
  profit_txt: '利潤',
  has_been_added: '已上架',
  on_display: '可上架',
  coming_soon_txt: '上架中',
  sold_txt: '已售出',
  removed_txt: '已下架',

  order_detail_txt: '訂單詳情',
  waiting_express_delivery: '等待快遞攬收',
  customer_info: '客戶資訊',
  order_good: '訂單商品',
  order_num: '訂單號碼',
  order_date: '訂單日期',
  order_content: '訂單摘要',
  purchase_price_txt: '進價',
  income_txt: '收入',

  order_txt: '訂單',
  pending_payment: '待付款',
  ready_for_shipment: '準備出貨',
  shipped_txt: '已出貨',
  paid_txt: '已交付',
  good_num: '商品編號',
  pay_and_ship: '付款與發貨',
  pay_suc: '支付成功',
  no_order: '暫無訂單',
  no_order_tips: '你可以在這裡追蹤你的訂單和退貨',
  suer_order: '確認付款',
  pay_way: '付款方式',
  wallet_pay: '錢包支付',
  sure_pay: '確認付款',
  paying_txt: '付款中...',

  manage_showcase: '管理櫥窗',
  add_new_good: '新增商品',
  heating_txt: '加熱',
  promotion: '推廣',
  promotion_tip: '推廣提示',
  promotion_tip_txt: '您目前的積分低於200，您無法為您的產品付款',
  copy_link: '複製連結',
  scan_qr_code: '掃描二維碼',

  about_good: '關於商品',
  specification: '規格',
  product_overview: '產品概覽',

  top_tips_1: '抖音店舖線上轉線上',
  top_tips_2: '目前僅支援內部邀請',
  active_place_holeder: '請輸入',

  promotion_01: '推廣',
  promotion_02: '推廣金額',
  promotion_03: '請輸入推廣金額',
  promotion_04: '確認推廣',
  promotion_05: '推廣成功',

  bao_txt: '曝光率',
  bal_no: '餘額不足',

  _yqm_01: '請填入正確啟動碼',

  all_orders: '全部訂單',
  mine_orders: '我的訂單',
  mine_services: '我的服務',

  _in_sp: '商品排行',
  _r_k01: '排名',
  _r_k02: '商品',
  _r_k03: '熱度',

  _c_201: '認證網域不存在',
  _c_202: '使用者已認證啟動',
  _c_204: '啟動碼已使用',

  gl_visits: '訪問量',

  hl_agreement: '同意',
  hl_service_agreement: '服務協定',
  hl_selected_agreement: '請勾選服務協議',

  service_agreement_content_1:
    '1.選擇適合的國家和地區：首先，您需要確定自己想要開設抖音店舖的特定國家或地區。 這需要您對該地區的消費市場、文化背景以及競爭情況有一定的了解和分析，以便為您的店鋪定位和經營策略提供依據。 ',
  service_agreement_content_2:
    '2.註冊抖音帳號：在確定好目標國家或地區後，您需要註冊一個屬於該地區的抖音帳號。 可以透過官方管道註冊，也可以尋找當地的中介機構協助完成註冊流程。 ',
  service_agreement_content_3:
    '3.準備資料：在註冊帳號時，您需要準備一些相關的資料，個人身份證資訊證明文件能夠讓抖音平台確認您的正當身份，500美元的開店保證金，並確保您的店鋪符合相關法律 法規要求。 ',
  service_agreement_content_4:
    '4.選擇經營模式：開設抖音店可以選擇自營模式或第三方進駐模式。 自營模式是指商家自行直接經營管理店鋪，而第三方入駐模式則是將店鋪交給第三方平台代經營。 根據自身實際情況和資源優勢，您可以選擇最適合自己的經營模式。 ',
  service_agreement_content_5:
    '5.產品準備和推廣：在店鋪開設之前，您需要準備足夠的產品庫存，並進行有效的市場推廣。 可以透過線上線下的方式進行推廣，例如購買流量套餐增加您的店鋪曝光度，吸引更多的用戶關注和購買。 ',
  service_agreement_content_6:
    ' 6.建立客戶服務體系：為了提供更好的使用者體驗，您需要建立完善的客戶服務體系。 包括及時回覆用戶的諮詢和投訴、處理售後問題等。 這樣可以增強使用者對您店舖的信任度，提高顧客滿意度和忠誠度。 ',
}
