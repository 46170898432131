export default {
  shop_center_txt: 'Centre commercial',
  score_txt: 'Points',
  minute_txt: 'minute',
  wallet_txt: 'portefeuille',
  today_txt: "Aujourd'hui",
  week_txt: 'cette semaine',
  month_txt: 'ce mois-ci',
  year_txt: 'année entière',
  main_indicators_txt: 'Principaux indicateurs',
  sale_price: 'prix de vente',
  expected_profit: 'bénéfice attendu',
  order_count: 'Quantité commandée',
  goods_click_count: 'Nombre de clics sur le produit',
  supplier_txt: 'Fournisseur',
  friends_help_txt: 'Aide des amis',
  feedback_txt: 'Commentaires',
  feedback: 'Commentaires',
  online_service_txt_1: 'Service client de dépôt',
  online_service_txt_2: 'Consultation commerciale',
  q_and_a_txt: 'Question et réponse',
  open_now_txt: 'Ouvrir',
  time_txt: 'heure',
  times_txt: 'heure',

  wallet_total_assets: 'Actifs totaux',
  balance_txt: 'Solde',
  pending_settlement_txt: 'règlement en attente',
  recharge_txt: 'Recharger',
  withdraw_txt: 'Retirer',
  fund_details_txt: 'Détails du fonds',
  no_more_txt: 'Plus rien',
  no_data_txt: "Aucune donnée pour l'instant",
  detail_txt: 'Détails',
  num_txt: 'Numéro',
  money_txt: 'montant',
  remark_txt: 'Remarques',
  no_remark_txt: 'Aucune remarque',
  tip_txt: 'astuce',
  tip_content_txt:
    'En raison de problèmes de devises actuellement souscrits par des commerçants dans divers pays',
  need_contact_service: 'Besoin de contacter le service client',
  contact_service: 'Contacter le service client',

  feedback_title: 'Donnez votre avis',
  feedback_placeholder:
    "Pour des raisons de sécurité, veuillez ne pas inclure d'informations personnelles",
  submit_txt: 'Soumettre',
  sure_txt: 'OK',
  submit_suc_txt: 'Soumission réussie',

  loading: 'Chargement...',

  newer_action: 'Activité des nouveaux arrivants',
  newer_traffic_package:
    'Package de trafic de support pour les nouveaux arrivants',
  newer_get_millions_of_products: 'Obtenez des millions de produits',
  newer_get: 'Reçu',
  newer_receive: 'recevoir',
  newer_action_ruler: "Règles d'activité des nouveaux arrivants",
  newer_receive_suc: 'Reçu avec succès',

  help_friends_help: 'Aide des amis',
  help_your_friends_stores: 'Aidez vos amis',
  get_store_product_heating: 'Obtenir le chauffage des produits du magasin',
  help_friends_txt: 'Aidez vos amis',
  help_friends_activity_rules: "Règles d'activité",
  assistance_details: "Détails de l'assistance",
  help_friend_name: "Nom de l'ami de l'aide",
  help_friend_invite_code: "Code d'invitation d'un ami d'aide",
  mine_invite_code: "Mon code d'invitation",
  plz_input: 'Veuillez entrer',
  plz_input_friend_name: 'Veuillez entrer le nom de votre ami pour vous aider',
  help_suc: 'Aide à la réussite',

  online_service: 'Service client en ligne',
  online_24_hour_service: 'Assistance en ligne 24 heures sur 24',
  customer_service: 'service client',
  copy_and_add_id: "Copier et ajouter l'ID de LIGNE",
  plz_input_question_detail: 'Veuillez saisir les détails de votre question',
  harry_reply_your: 'Nous vous répondrons dans les plus brefs délais',
  click_copy: 'Cliquez pour copier',
  copy_suc: 'Copie réussie',
  copy_fail: 'La copie a échoué',

  overall: 'tous',
  cooperate: 'coopérer',
  cancel: 'annuler',
  supplier_num: 'Numéro du fournisseur',
  no_use_supplier: 'Aucun fournisseur disponible',
  here_add_supplier: 'Ajouter un nouveau fournisseur ici',
  add_supplier: 'Ajouter un fournisseur',
  plz_input_supplier_num: 'Veuillez saisir le numéro du fournisseur',
  add_suc: 'Ajouter avec succès',

  new_products_launched: 'Nouveaux produits lancés',
  profit_txt: 'Bénéfice',
  has_been_added: 'Déjà en rayon',
  on_display: 'Peut être mis en étagère',
  coming_soon_txt: 'Disponible maintenant',
  sold_txt: 'Vendu',
  removed_txt: 'Supprimé',

  order_detail_txt: 'Détails de la commande',
  waiting_express_delivery: 'En attente de livraison express',
  customer_info: 'Informations client',
  order_good: 'Commander des marchandises',
  order_num: 'Numéro de commande',
  order_date: 'date de commande',
  order_content: 'Résumé de la commande',
  purchase_price_txt: "Prix d'achat",
  income_txt: 'Revenu',

  order_txt: 'Commande',
  ending_payment: 'paiement en attente',
  ready_for_shipment: 'À expédier',
  shipped_txt: 'expédié',
  paid_txt: 'Livré',
  good_num: "numéro d'article",
  pay_and_ship: 'Paiement et expédition',
  pay_suc: 'Paiement réussi',
  no_order: 'Pas encore de commande',
  no_order_tips: 'Vous pouvez suivre vos commandes et retours ici',
  suer_order: 'Confirmer le paiement',
  pay_way: 'mode de paiement',
  wallet_pay: 'paiement par portefeuille',
  sure_pay: 'Confirmer le paiement',
  paying_txt: 'Payer...',

  manage_showcase: 'Gérer la vitrine',
  add_new_good: 'Ajouter un nouveau produit',
  heating_txt: 'Chauffage',
  promotion: 'promotion',
  promotion_tip: 'Conseil promotionnel',
  promotion_tip_txt:
    'Vos points actuels sont inférieurs à 200 et vous ne pouvez pas payer vos produits',
  copy_link: 'Copier le lien',
  scan_qr_code: 'Scanner le code QR',

  about_good: 'À propos du produit',
  specification: 'spécification',
  product_overview: 'Présentation du produit',

  top_tips_1: 'Transformez la boutique Douyin en ligne',
  top_tips_2: 'Actuellement, ne prend en charge que les invitations internes',

  certification_activation_code: "code d'invitation",
  plz_write_invite_code: "Veuillez saisir le code d'invitation",
  active_user_email: 'e-mail',
  plz_input_active_user_email: 'Veuillez entrer votre email',
  active_user_email_code: "Code de vérification de l'e-mail",
  plz_input_active_user_email_code:
    "Veuillez saisir le code de vérification de l'e-mail",
  active_get_email_code_txt: 'Obtenir le code de vérification',
  active_user_name: 'nom',
  plz_input_active_user_name: 'Veuillez entrer votre nom',
  active_user_id: "numéro de carte d'identité/passeport",
  plz_input_active_user_id:
    "Veuillez entrer votre numéro de carte d'identité/passeport",
  upload_active_user_id: 'Téléchargement du certificat',
  plz_upload_user_id_img:
    "Veuillez télécharger la photo du recto de votre carte d'identité/page de passeport",

  promotion_01: 'Promotion',
  promotion_02: 'Montant de la promotion',
  promotion_03: 'Veuillez saisir le montant de la promotion',
  promotion_04: 'Confirmer la promotion',
  promotion_05: 'Promotion réussie',

  bao_txt: "Taux d'exposition",
  bal_no: 'Solde insuffisant',

  _yqm_01: 'Veuillez remplir le bon code d’activation',

  all_orders: 'Toutes les commandes',
  mine_orders: 'Mes commandes',
  mine_services: 'Mes services',

  _in_sp: 'Classement des produits',
  _r_k01: 'Classement',
  _r_k02: 'Marchandise',
  _r_k03: 'chaleur',

  _c_201: 'Le nom de domaine d’authentification n’existe pas',
  _c_202: 'L’utilisateur a été authentifié et activé',
  _c_204: 'Le code d’activation a été utilisé',

  gl_visits: 'Visites',

  hl_agreement: "d'accord",
  hl_service_agreement: 'Contrat de service',
  hl_selected_agreement: 'Veuillez vérifier le contrat de service',

  service_agreement_content_1:
    '1. Choisissez un pays et une région appropriés : Tout d`abord, vous devez déterminer le pays ou la région spécifique dans lequel vous souhaitez ouvrir un magasin Douyin. Cela nécessite que vous ayez une certaine compréhension et analyse du marché de consommation, du contexte culturel et de la concurrence de la région pour fournir une base au positionnement de votre magasin et à votre stratégie commerciale. ',
  service_agreement_content_2:
    '2. Enregistrez un compte Douyin : Après avoir déterminé le pays ou la région cible, vous devez créer un compte Douyin appartenant à cette région. Vous pouvez vous inscrire via les canaux officiels ou trouver un intermédiaire local pour vous aider à terminer le processus d`inscription. ',
  service_agreement_content_3:
    '3. Préparer les informations : lors de l`enregistrement d`un compte, vous devez préparer certaines informations pertinentes. Les documents de certification des informations de carte d`identité personnelle peuvent permettre à la plateforme Douyin de confirmer votre identité légitime, un dépôt d`ouverture de magasin de 500 $ et de garantir que votre magasin est conforme aux exigences pertinentes. lois, exigences réglementaires. ',
  service_agreement_content_4:
    '4. Choisissez un modèle commercial : lors de l`ouverture d`un magasin Douyin, vous pouvez choisir un modèle auto-exploité ou un modèle d`entrée tiers. Le modèle auto-exploité signifie que le commerçant exploite et gère directement le magasin lui-même, tandis que le modèle d`entrée par un tiers signifie que le magasin est confié à une plate-forme tierce pour son exploitation. En fonction de votre situation réelle et de vos avantages en matière de ressources, vous pouvez choisir le modèle économique qui vous convient le mieux. ',
  service_agreement_content_5:
    '5. Préparation et promotion des produits : avant d`ouvrir un magasin, vous devez préparer un inventaire de produits suffisant et mener une commercialisation efficace. Vous pouvez en faire la promotion via des méthodes en ligne et hors ligne, telles que l`achat d`un forfait de trafic pour augmenter la visibilité de votre magasin et attirer davantage d`utilisateurs à prêter attention et à acheter. ',
  service_agreement_content_6:
    '6. Établir un système de service client : Afin d`offrir une meilleure expérience utilisateur, vous devez établir un système de service client complet. Y compris répondre rapidement aux demandes et plaintes des utilisateurs, gérer les problèmes après-vente, etc. Cela peut renforcer la confiance des utilisateurs dans votre magasin et accroître la satisfaction et la fidélité des clients. ',
}
