export default {
  shop_center_txt: '商铺中心',
  score_txt: '积分',
  minute_txt: '分',
  wallet_txt: '钱包',
  today_txt: '本日',
  week_txt: '本周',
  month_txt: '本月',
  year_txt: '整年',
  main_indicators_txt: '主要指标',
  sale_price: '销售价格',
  expected_profit: '预期利润',
  order_count: '订单数量',
  goods_click_count: '产品点击数',
  supplier_txt: '供应商',
  friends_help_txt: '朋友助力',
  feedback_txt: '反馈',
  feedback: '意见反馈',
  online_service_txt_1: '入金客服',
  online_service_txt_2: '业务咨询',
  q_and_a_txt: '问答',
  open_now_txt: '开启',
  certification_activation_code: '认证激活码',
  plz_write_invite_code: '请填写邀请码',
  time_txt: '时',
  times_txt: '时间',

  wallet_total_assets: '总财产',
  balance_txt: '余额',
  pending_settlement_txt: '待结算',
  recharge_txt: '充值',
  withdraw_txt: '提现',
  fund_details_txt: '资金明细',
  no_more_txt: '没有更多了',
  no_data_txt: '暂无数据',
  detail_txt: '明细详情',
  num_txt: '编号',
  money_txt: '金额',
  remark_txt: '备注',
  no_remark_txt: '无备注',
  tip_txt: '提示',
  tip_content_txt: '由于各国商户承销币种问题，目前',
  need_contact_service: '需联系客服',
  contact_service: '联系客服',

  feedback_title: '反馈你的意见',
  feedback_placeholder: '出于安全考虑，请勿包含个人信息',
  submit_txt: '提交',
  sure_txt: '确定',
  submit_suc_txt: '提交成功',

  loading: '加载中...',

  newer_action: '新人活动',
  newer_traffic_package: '新人扶持流量包',
  newer_get_millions_of_products: '领取百万商品',
  newer_get: '收到',
  newer_receive: '领取',
  newer_action_ruler: '新人活动规则',
  newer_receive_suc: '领取成功',

  help_friends_help: '好友助力',
  help_your_friends_stores: '助力你的好友店铺',
  get_store_product_heating: '获取店铺商品加热',
  help_friends_txt: '助力朋友',
  help_friends_activity_rules: '助力好友活动规则',
  assistance_details: '助力明细',
  help_friend_name: '助力好友名',
  plz_input: '请输入',
  plz_input_friend_name: '请输入助力好友名',
  help_suc: '助力成功',

  online_service: '在线客服',
  online_24_hour_service: '24小时在线支持',
  customer_service: '客户服务',
  copy_and_add_id: '复制并添加LINE ID',
  plz_input_question_detail: '请输入你的问题的详细信息',
  harry_reply_your: '我们会尽快回复你',
  click_copy: '点击复制',
  copy_suc: '复制成功',
  copy_fail: '复制失败',

  overall: '整体',
  cooperate: '合作',
  cancel: '取消',
  supplier_num: '供货商编号',
  no_use_supplier: '没有可用的供应商',
  here_add_supplier: '在这里添加新供应商',
  add_supplier: '添加供货商',
  plz_input_supplier_num: '请输入供货商编号',
  add_suc: '添加成功',

  new_products_launched: '上架新商品',
  profit_txt: '利润',
  has_been_added: '已上架',
  on_display: '可上架',
  coming_soon_txt: '上架中',
  sold_txt: '已售出',
  removed_txt: '已下架',

  order_detail_txt: '订单详情',
  waiting_express_delivery: '等待快递揽收',
  customer_info: '客户信息',
  order_good: '订单商品',
  order_num: '订单号',
  order_date: '订单日期',
  order_content: '订单摘要',
  purchase_price_txt: '进价',
  income_txt: '收入',

  order_txt: '订单',
  pending_payment: '待付款',
  ready_for_shipment: '准备发货',
  shipped_txt: '已发货',
  paid_txt: '已交付',
  good_num: '商品编号',
  pay_and_ship: '付款与发货',
  pay_suc: '支付成功',
  no_order: '暂无订单',
  no_order_tips: '你可以在这里追踪你的订单和退货',
  suer_order: '确认付款',
  pay_way: '支付方式',
  wallet_pay: '钱包支付',
  sure_pay: '确认支付',
  paying_txt: '支付中...',

  manage_showcase: '管理橱窗',
  add_new_good: '添加新商品',
  heating_txt: '加热',
  promotion: '推广',
  promotion_tip: '推广提示',
  promotion_tip_txt: '您当前的积分低于200，您无法为你的产品付款',
  copy_link: '复制链接',
  scan_qr_code: '扫描二维码',

  about_good: '关于商品',
  specification: '规格',
  product_overview: '产品概览',

  top_tips_1: '抖音店铺线上转线上',
  top_tips_2: '目前仅支持内部邀请',
  active_place_holeder: '请输入',

  promotion_01: '推广',
  promotion_02: '推广金额',
  promotion_03: '请输入推广金额',
  promotion_04: '确认推广',
  promotion_05: '推广成功',

  bao_txt: '曝光率',
  bal_no: '余额不足',

  _yqm_01: '请填写正确激活码',

  all_orders: '全部订单',
  mine_orders: '我的订单',
  mine_services: '我的服务',

  _in_sp: '商品排行',
  _r_k01: '排名',
  _r_k02: '商品',
  _r_k03: '热度',

  _c_201: '认证域名不存在',
  _c_202: '用户已认证激活',
  _c_204: '激活码已使用',

  gl_visits: '访问量',

  hl_agreement: '同意',
  hl_service_agreement: '服务协议',
  hl_selected_agreement: '请勾选服务协议',

  service_agreement_content_1:
    '1.选择适合的国家和地区：首先，您需要确定自己想要开设抖音店铺的具体国家或地区。这需要您对该地区的消费市场、文化背景以及竞争情况有一定的了解和分析，以便为您的店铺定位和经营策略提供依据。',
  service_agreement_content_2:
    '2.注册抖音账号：在确定好目标国家或地区后，您需要注册一个属于该地区的抖音账号。可以通过官方渠道进行注册，也可以寻找当地的中介机构协助完成注册流程。',
  service_agreement_content_3:
    '3.准备资料：在注册账号时，您需要准备一些相关的资料，个人身份证信息证明文件能够让抖音平台确认您的正当身份，500美元的开店保证金，并确保您的店铺符合相关法律法规要求。',
  service_agreement_content_4:
    '4.选择经营模式：开设抖音店铺可以选择自营模式或者第三方入驻模式。自营模式是指商家自己直接经营和管理店铺，而第三方入驻模式则是将店铺交给第三方平台代运营。根据自身实际情况和资源优势，您可以选择最适合自己的经营模式。',
  service_agreement_content_5:
    '5.产品准备和推广：在店铺开设之前，您需要准备好足够的产品库存，并进行有效的市场推广。可以通过线上线下的方式进行推广，例如购买流量套餐增加您的店铺曝光度，吸引更多的用户关注和购买。',
  service_agreement_content_6:
    ' 6.建立客户服务体系：为了提供更好的用户体验，您需要建立完善的客户服务体系。包括及时回复用户的咨询和投诉、处理售后问题等。这样可以增强用户对您店铺的信任度，提高客户满意度和忠诚度。',
}
