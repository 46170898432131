export default {
  shop_center_txt: 'Shop center',
  score_txt: 'Points',
  minute_txt: 'Minute',
  wallet_txt: 'Wallet',
  today_txt: 'Today',
  week_txt: 'Week',
  month_txt: 'Month',
  year_txt: 'Year',
  main_indicators_txt: 'Main indicators',
  sale_price: 'Sale price',
  expected_profit: 'Expected profit',
  order_count: 'Order quantity',
  goods_click_count: 'Number of product clicks',
  supplier_txt: 'Supplier',
  friends_help_txt: 'Friends help',
  feedback_txt: 'Feedback',
  feedback: 'Feedback',
  online_service_txt_1: 'Deposit customer service',
  online_service_txt_2: 'Business consultation',
  q_and_a_txt: 'Question and Answer',
  open_now_txt: 'Open',
  certification_activation_code: 'Certification activation code',
  plz_write_invite_code: 'Please fill in the invitation code',
  time_txt: 'Hour',
  times_txt: 'Time',

  wallet_total_assets: 'Total assets',
  balance_txt: 'Balance',
  pending_settlement_txt: 'pending settlement',
  recharge_txt: 'Recharge',
  withdraw_txt: 'Withdraw',
  fund_details_txt: 'Fund details',
  no_more_txt: 'No more',
  no_data_txt: 'No data yet',
  detail_txt: 'Details',
  num_txt: 'Number',
  money_txt: 'Amount',
  remark_txt: 'Remarks',
  no_remark_txt: 'No remark',
  tip_txt: 'Tip',
  tip_content_txt:
    'Due to currency issues underwritten by merchants in various countries, currently',
  need_contact_service: 'Need to contact customer service',
  contact_service: 'Contact customer service',

  feedback_title: 'Feedback your opinion',
  feedback_placeholder:
    'For security reasons, please do not include personal information',
  submit_txt: 'Submit',
  sure_txt: 'OK',
  submit_suc_txt: 'Submission successful',

  loading: 'loading...',

  newer_action: 'Newcomer activity',
  newer_traffic_package: 'Newcomer support traffic package',
  newer_get_millions_of_products: 'Get millions of products',
  newer_get: 'Received',
  newer_receive: 'Receive',
  newer_action_ruler: 'Newcomer activity rules',
  newer_receive_suc: 'Received successfully',

  help_friends_help: 'Friends help',
  help_your_friends_stores: 'Help your friends` stores',
  get_store_product_heating: 'Get store product heating',
  help_friends_txt: 'Help friends',
  help_friends_activity_rules: 'Help friends activity rules',
  assistance_details: 'Assistance details',
  help_friend_name: 'Help friend name',
  plz_input: 'Please enter',
  plz_input_friend_name: 'Please enter the name of your friend to help',
  help_suc: 'Help success',

  online_service: 'Online customer service',
  online_24_hour_service: '24-hour online support',
  customer_service: 'Customer Service',
  copy_and_add_id: 'Copy and add LINE ID',
  plz_input_question_detail: 'Please enter the details of your question',
  harry_reply_your: 'We will reply to you as soon as possible',
  click_copy: 'Click to copy',
  copy_suc: 'Copy successful',
  copy_fail: 'Copy failed',

  overall: 'Overall',
  cooperate: 'Cooperate',
  cancel: 'Cancel',
  supplier_num: 'Supplier number',
  no_use_supplier: 'No available supplier',
  here_add_supplier: 'Add new supplier here',
  add_supplier: 'Add supplier',
  plz_input_supplier_num: 'Please enter the supplier number',
  add_suc: 'Add successfully',

  new_products_launched: 'New products launched',
  profit_txt: 'Profit',
  has_been_added: 'Already on the shelves',
  on_display: 'On display',
  coming_soon_txt: 'Available now',
  sold_txt: 'Sold',
  removed_txt: 'Removed',

  order_detail_txt: 'Order details',
  waiting_express_delivery: 'Waiting for express delivery',
  customer_info: 'Customer information',
  order_good: 'Order Goods',
  order_num: 'Order number',
  order_date: 'order date',
  order_content: 'Order Summary',
  purchase_price_txt: 'Purchase price',
  income_txt: 'Income',

  order_txt: 'Order',
  pending_payment: 'Pending payment',
  ready_for_shipment: 'Ready for shipment',
  shipped_txt: 'Shipped',
  paid_txt: 'Delivered',
  good_num: 'Item number',
  pay_and_ship: 'Payment and Shipment',
  pay_suc: 'Payment successful',
  no_order: 'No order yet',
  no_order_tips: 'You can track your orders and returns here',
  suer_order: 'Confirm payment',
  pay_way: 'payment method',
  wallet_pay: 'Wallet payment',
  sure_pay: 'Confirm payment',
  paying_txt: 'Paying...',

  manage_showcase: 'Manage showcase',
  add_new_good: 'Add new product',
  heating_txt: 'Heating',
  promotion: 'Promotion',
  promotion_tip: 'Promotion tip',
  promotion_tip_txt:
    'Your current points are less than 200 and you cannot pay for your products',
  copy_link: 'Copy link',
  scan_qr_code: 'Scan QR code',

  about_good: 'About the product',
  specification: 'Specification',
  product_overview: 'Product Overview',

  top_tips_1: 'Tiktok Online to Online',
  top_tips_2: 'Currently only internal invitations are supported',
  active_place_holeder: 'Please enter',

  promotion_01: 'Promotion',
  promotion_02: 'Promotion amount',
  promotion_03: 'Please enter the promotion amount',
  promotion_04: 'Confirm promotion',
  promotion_05: 'Promotion successful',

  bao_txt: 'Exposure',
  bal_no: 'Insufficient balance',

  _yqm_01: 'Please fill in the correct activation code',

  all_orders: 'All orders',
  mine_orders: 'My orders',
  mine_services: 'My Services',

  _in_sp: 'Product ranking',
  _r_k01: 'Ranking',
  _r_k02: 'Commodity',
  _r_k03: 'heat',

  _c_201: 'Authentication domain name does not exist',
  _c_202: 'User has been authenticated and activated',
  _c_204: 'Activation code has been used',

  gl_visits: 'Visits',

  hl_agreement: 'Agree',
  hl_service_agreement: 'Service Agreement',
  hl_selected_agreement: 'Please check the service agreement',

  service_agreement_content_1:
    "1. Choose a suitable country and region: First, you need to determine the specific country or region where you want to open a Douyin store. This requires you to have a certain understanding and analysis of the region's consumer market, cultural background, and competition to provide a basis for your store positioning and business strategy. ",
  service_agreement_content_2:
    '2. Register a Douyin account: After determining the target country or region, you need to register a Douyin account belonging to that region. You can register through official channels, or you can find a local intermediary to help complete the registration process. ',
  service_agreement_content_3:
    '3. Prepare information: When registering an account, you need to prepare some relevant information. Personal ID card information certification documents can allow the Douyin platform to confirm your legitimate identity, a $500 store opening deposit, and ensure that your store complies with relevant laws. regulatory requirements. ',
  service_agreement_content_4:
    '4. Choose a business model: When opening a Douyin store, you can choose a self-operated model or a third-party entry model. The self-operated model means that the merchant directly operates and manages the store itself, while the third-party entry model means that the store is handed over to a third-party platform for operation. Based on your actual situation and resource advantages, you can choose the business model that best suits you. ',
  service_agreement_content_5:
    '5. Product preparation and promotion: Before opening a store, you need to prepare sufficient product inventory and conduct effective marketing. You can promote it through online and offline methods, such as purchasing a traffic package to increase the exposure of your store and attract more users to pay attention and purchase. ',
  service_agreement_content_6:
    "6. Establish a customer service system: In order to provide a better user experience, you need to establish a complete customer service system. Including promptly responding to user inquiries and complaints, handling after-sales issues, etc. This can enhance users'trust in your store and increase customer satisfaction and loyalty. ",
}
