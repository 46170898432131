export default {
  shop_center_txt: 'Einkaufszentrum',
  score_txt: 'Punkte',
  minute_txt: 'Minute',
  wallet_txt: 'Wallet',
  today_txt: 'Heute',
  week_txt: 'diese Woche',
  month_txt: 'diesen Monat',
  year_txt: 'ganzes Jahr',
  main_indicators_txt: 'Hauptindikatoren',
  sale_price: 'Verkaufspreis',
  expected_profit: 'erwarteter Gewinn',
  order_count: 'Bestellmenge',
  goods_click_count: 'Anzahl der Produktklicks',
  supplier_txt: 'Lieferant',
  friends_help_txt: 'Freunde helfen',
  feedback_txt: 'Feedback',
  feedback: 'Rückmeldung',
  online_service_txt_1: 'Kundenservice einzahlen',
  online_service_txt_2: 'Unternehmensberatung',
  q_and_a_txt: 'Frage und Antwort',
  open_now_txt: 'Öffnen',
  time_txt: 'Zeit',
  times_txt: 'Zeit',

  wallet_total_assets: 'Gesamtvermögen',
  balance_txt: 'Balance',
  pending_settlement_txt: 'Ausstehende Abrechnung',
  recharge_txt: 'Aufladen',
  withdraw_txt: 'Zurückziehen',
  fund_details_txt: 'Fondsdetails',
  no_more_txt: 'Nicht mehr',
  no_data_txt: 'Noch keine Daten',
  detail_txt: 'Details',
  num_txt: 'Nummer',
  money_txt: 'Betrag',
  remark_txt: 'Bemerkungen',
  no_remark_txt: 'Kein Kommentar',
  tip_txt: 'tip',
  tip_content_txt:
    'Aufgrund von Währungsproblemen, die derzeit von Händlern in verschiedenen Ländern übernommen werden',
  need_contact_service: 'Sie müssen den Kundendienst kontaktieren',
  contact_service: 'Kundendienst kontaktieren',

  feedback_title: 'Feedback Ihrer Meinung',
  feedback_placeholder:
    'Bitte geben Sie aus Sicherheitsgründen keine persönlichen Daten an',
  submit_txt: 'Senden',
  sure_txt: 'OK',
  submit_suc_txt: 'Übermittlung erfolgreich',

  loading: 'Laden...',

  newer_action: 'Neulingsaktivität',
  newer_traffic_package: 'Newcomer-Support-Traffic-Paket',
  newer_get_millions_of_products: 'Millionen Produkte erhalten',
  newer_get: 'Received',
  newer_receive: 'empfangen',
  newer_action_ruler: 'Aktivitätsregeln für Neulinge',
  newer_receive_suc: 'Erfolgreich empfangen',

  help_friends_help: 'Freunde helfen',
  help_your_friends_stores: 'Freunden helfen',
  get_store_product_heating: 'Ladenproduktheizung abrufen',
  help_friends_txt: 'Freunden helfen',
  help_friends_activity_rules: 'Aktivitätsregeln',
  assistance_details: 'Hilfedetails',
  help_friend_name: 'Name des Freundes helfen',
  help_friend_invite_code: 'Einladungscode für Freunde helfen',
  mine_invite_code: 'Mein Einladungscode',
  plz_input: 'Bitte eingeben',
  plz_input_friend_name:
    'Bitte geben Sie den Namen Ihres Freundes ein, um zu helfen',
  help_suc: 'Hilfe zum Erfolg',

  online_service: 'Online-Kundenservice',
  online_24_hour_service: '24-Stunden-Online-Support',
  customer_service: 'Kundenservice',
  copy_and_add_id: 'Zeilen-ID kopieren und hinzufügen',
  plz_input_question_detail: 'Bitte geben Sie die Details Ihrer Frage ein',
  harry_reply_your: 'Wir werden Ihnen so schnell wie möglich antworten',
  click_copy: 'Zum Kopieren klicken',
  copy_suc: 'Kopieren erfolgreich',
  copy_fail: 'Kopieren fehlgeschlagen',

  overall: 'alle',
  cooperate: 'kooperieren',
  cancel: 'abbrechen',
  supplier_num: 'Lieferantennummer',
  no_use_supplier: 'Kein Lieferant verfügbar',
  here_add_supplier: 'Hier neuen Lieferanten hinzufügen',
  add_supplier: 'Lieferant hinzufügen',
  plz_input_supplier_num: 'Bitte geben Sie die Lieferantennummer ein',
  add_suc: 'Erfolgreich hinzufügen',

  new_products_launched: 'Neue Produkte eingeführt',
  profit_txt: 'Gewinn',
  has_been_added: 'Bereits in den Regalen',
  on_display: 'Kann in Regale gestellt werden',
  coming_soon_txt: 'Jetzt verfügbar',
  sold_txt: 'Verkauft',
  removed_txt: 'Entfernt',

  order_detail_txt: 'Bestelldetails',
  waiting_express_delivery: 'Warten auf Expresslieferung',
  customer_info: 'Kundeninformationen',
  order_good: 'Waren bestellen',
  order_num: 'Bestellnummer',
  order_date: 'Bestelldatum',
  order_content: 'Bestellzusammenfassung',
  purchase_price_txt: 'Kaufpreis',
  income_txt: 'Einkommen',

  order_txt: 'Bestellung',
  pending_zahlung: 'Zahlung ausstehend',
  ready_for_shipment: 'Zu versenden',
  shipped_txt: 'versendet',
  paid_txt: 'Geliefert',
  good_num: 'Artikelnummer',
  pay_and_ship: 'Zahlung und Versand',
  pay_suc: 'Zahlung erfolgreich',
  no_order: 'Noch keine Bestellung',
  no_order_tips: 'Hier können Sie Ihre Bestellungen und Retouren verfolgen',
  suer_order: 'Zahlung bestätigen',
  pay_way: 'Zahlungsmethode',
  wallet_pay: 'Wallet-Zahlung',
  sure_pay: 'Zahlung bestätigen',
  paying_txt: 'Bezahlt...',

  manage_showcase: 'Showcase verwalten',
  add_new_good: 'Neues Produkt hinzufügen',
  heating_txt: 'Heizung',
  promotion: 'Beförderung',
  promotion_tip: 'Werbetipp',
  promotion_tip_txt:
    'Ihr aktueller Punktestand liegt unter 200 und Sie können Ihre Produkte nicht bezahlen',
  copy_link: 'Link kopieren',
  scan_qr_code: 'QR-Code scannen',

  about_good: 'Über das Produkt',
  specification: 'Spezifikation',
  product_overview: 'Produktübersicht',

  top_tips_1: 'Online-Überweisung im Douyin-Shop',
  top_tips_2: 'Unterstützt derzeit nur interne Einladungen',

  certification_activation_code: 'Einladungscode',
  plz_write_invite_code: 'Bitte geben Sie den Einladungscode ein',
  active_user_email: 'E-Mail',
  plz_input_active_user_email: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
  active_user_email_code: 'E-Mail-Bestätigungscode',
  plz_input_active_user_email_code:
    'Bitte geben Sie den E-Mail-Bestätigungscode ein',
  active_get_email_code_txt: 'Bestätigungscode abrufen',
  active_user_name: 'Name',
  plz_input_active_user_name: 'Bitte geben Sie Ihren Namen ein',
  active_user_id: 'Personalausweis-/Reisepassnummer',
  plz_input_active_user_id:
    'Bitte geben Sie Ihre Personalausweis-/Reisepassnummer ein',
  upload_active_user_id: 'Zertifikat-Upload',
  plz_upload_user_id_img:
    'Bitte laden Sie das Foto der Vorderseite Ihres Personalausweises/Reisepasses hoch',

  promotion_01: 'Promotion',
  promotion_02: 'Aktionsbetrag',
  promotion_03: 'Bitte geben Sie den Aktionsbetrag ein',
  promotion_04: 'Promotion bestätigen',
  promotion_05: 'Promotion erfolgreich',

  bao_txt: 'Belichtungsrate',
  bal_no: 'Unzureichendes Guthaben',

  _yqm_01: 'Bitte geben Sie den richtigen Aktivierungscode ein',

  all_orders: 'Alle Bestellungen',
  mine_orders: 'Meine Bestellungen',
  mine_services: 'Meine Dienste',

  _in_sp: 'Produktranking',
  _r_k01: 'Rangliste',
  _r_k02: 'Ware',
  _r_k03: 'Hitze',

  _c_201: 'Authentifizierungsdomänenname existiert nicht',
  _c_202: 'Benutzer wurde authentifiziert und aktiviert',
  _c_204: 'Aktivierungscode wurde verwendet',

  gl_visits: 'Besuche',

  hl_agreement: 'zustimmen',
  hl_service_agreement: 'Servicevereinbarung',
  hl_selected_agreement: 'Bitte überprüfen Sie die Servicevereinbarung',

  service_agreement_content_1:
    '1. Wählen Sie ein geeignetes Land und eine geeignete Region: Zunächst müssen Sie das spezifische Land oder die Region bestimmen, in der Sie ein Douyin-Geschäft eröffnen möchten. Dies erfordert ein gewisses Verständnis und eine gewisse Analyse des Verbrauchermarkts, des kulturellen Hintergrunds und der Konkurrenz in der Region, um eine Grundlage für die Positionierung Ihres Geschäfts und Ihre Geschäftsstrategie zu schaffen. ',
  service_agreement_content_2:
    '2. Registrieren Sie ein Douyin-Konto: Nachdem Sie das Zielland oder die Zielregion festgelegt haben, müssen Sie ein Douyin-Konto registrieren, das zu dieser Region gehört. Sie können sich über offizielle Kanäle registrieren oder einen lokalen Vermittler finden, der Sie beim Abschluss des Registrierungsprozesses unterstützt. ',
  service_agreement_content_3:
    '3. Bereiten Sie Informationen vor: Bei der Registrierung eines Kontos müssen Sie einige relevante Informationen vorbereiten. Dokumente zur Zertifizierung persönlicher Ausweisinformationen können es der Douyin-Plattform ermöglichen, Ihre legitime Identität zu bestätigen, eine Anzahlung von 500 US-Dollar für die Eröffnung des Geschäfts zu leisten und sicherzustellen, dass Ihr Geschäft den relevanten Anforderungen entspricht Gesetze. Regulatorische Anforderungen. ',
  service_agreement_content_4:
    '4. Wählen Sie ein Geschäftsmodell: Bei der Eröffnung eines Douyin-Stores können Sie zwischen einem selbst betriebenen Modell oder einem Einstiegsmodell eines Drittanbieters wählen. Das Self-Operated-Modell bedeutet, dass der Händler den Shop direkt selbst betreibt und verwaltet, während das Third-Party-Entry-Modell bedeutet, dass der Store an eine Drittanbieterplattform zum Betrieb übergeben wird. Basierend auf Ihrer tatsächlichen Situation und Ihren Ressourcenvorteilen können Sie das Geschäftsmodell auswählen, das am besten zu Ihnen passt. ',
  service_agreement_content_5:
    '5. Produktvorbereitung und Werbung: Bevor Sie ein Geschäft eröffnen, müssen Sie einen ausreichenden Produktbestand vorbereiten und effektives Marketing betreiben. Sie können es durch Online- und Offline-Methoden bewerben, z. B. durch den Kauf eines Traffic-Pakets, um die Bekanntheit Ihres Shops zu erhöhen und mehr Benutzer zum Kauf zu bewegen. ',
  service_agreement_content_6:
    '6. Richten Sie ein Kundendienstsystem ein: Um ein besseres Benutzererlebnis zu bieten, müssen Sie ein vollständiges Kundendienstsystem einrichten. Dazu gehören die schnelle Reaktion auf Benutzeranfragen und -beschwerden, die Bearbeitung von After-Sales-Problemen usw. Dies kann das Vertrauen der Nutzer in Ihr Geschäft stärken und die Kundenzufriedenheit und -treue steigern. ',
}
