import { createStore } from 'vuex'

export default createStore({
  state: {
    // 002 001 004
    index_noLogin: require('../assets/img/noLogin_002.png'),
    // 003
    // index_noLogin: require('../assets/img/noLogin_003.png'),
  },
})
