export default {
  shop_center_txt: 'Pusat toko',
  score_txt: 'Poin',
  minute_txt: 'menit',
  wallet_txt: 'dompet',
  today_txt: 'Hari ini',
  week_txt: 'minggu ini',
  month_txt: 'bulan ini',
  year_txt: 'setahun penuh',
  main_indicators_txt: 'Indikator utama',
  sale_price: 'harga jual',
  expected_profit: 'keuntungan yang diharapkan',
  order_count: 'Jumlah pesanan',
  goods_click_count: 'Jumlah klik produk',
  supplier_txt: 'Pemasok',
  friends_help_txt: 'Bantuan teman',
  feedback_txt: 'Masukan',
  feedback: 'Umpan Balik',
  online_service_txt_1: 'Layanan pelanggan setor',
  online_service_txt_2: 'Konsultasi bisnis',
  q_and_a_txt: 'Tanya Jawab',
  open_now_txt: 'Buka',
  time_txt: 'waktu',
  times_txt: 'waktu',

  wallet_total_assets: 'Total aset',
  balance_txt: 'Saldo',
  pending_settlement_txt: 'penyelesaian tertunda',
  recharge_txt: 'Isi ulang',
  withdraw_txt: 'Penarikan',
  fund_details_txt: 'Detail dana',
  no_more_txt: 'Tidak ada lagi',
  no_data_txt: 'Belum ada data',
  detail_txt: 'Detail',
  num_txt: 'Nomor',
  money_txt: 'jumlah',
  remark_txt: 'Keterangan',
  no_remark_txt: 'Tidak ada komentar',
  tip_txt: 'tip',
  tip_content_txt:
    'Saat ini karena masalah mata uang yang ditanggung oleh pedagang di berbagai negara',
  need_contact_service: 'Perlu menghubungi layanan pelanggan',
  contact_service: 'Hubungi layanan pelanggan',

  feedback_title: 'Masukan pendapat Anda',
  feedback_placeholder:
    'Untuk alasan keamanan, mohon jangan sertakan informasi pribadi',
  submit_txt: 'Kirim',
  sure_txt: 'Oke',
  submit_suc_txt: 'Pengiriman berhasil',

  loading: 'Memuat...',

  newer_action: 'Aktivitas pendatang baru',
  newer_traffic_package: 'Paket lalu lintas dukungan pendatang baru',
  newer_get_millions_of_products: 'Dapatkan jutaan produk',
  newer_get: 'Diterima',
  newer_receive: 'menerima',
  newer_action_ruler: 'Aturan aktivitas pendatang baru',
  newer_receive_suc: 'Berhasil diterima',

  help_friends_help: 'Bantuan teman',
  help_your_friends_stores: 'Bantu teman',
  get_store_product_heating: 'Dapatkan pemanas produk toko',
  help_friends_txt: 'Bantu teman',
  help_friends_activity_rules: 'Aturan aktivitas',
  assistance_details: 'Detail bantuan',
  help_friend_name: 'Bantuan nama teman',
  help_friend_invite_code: 'Bantuan kode undangan teman',
  mine_invite_code: 'Kode undangan saya',
  plz_input: 'Silakan masuk',
  plz_input_friend_name: 'Silakan masukkan nama teman Anda untuk membantu',
  help_suc: 'Bantu kesuksesan',

  online_service: 'Layanan pelanggan online',
  online_24_hour_service: 'dukungan online 24 jam',
  customer_service: 'layanan pelanggan',
  copy_and_add_id: 'Salin dan tambahkan ID LINE',
  plz_input_question_detail: 'Silahkan masukkan rincian pertanyaan Anda',
  harry_reply_your: 'Kami akan membalas Anda sesegera mungkin',
  click_copy: 'Klik untuk menyalin',
  copy_suc: 'Penyalinan berhasil',
  copy_fail: 'Penyalinan gagal',

  overall: 'semua',
  cooperate: 'bekerja sama',
  cancel: 'batal',
  supplier_num: 'Nomor pemasok',
  no_use_supplier: 'Tidak ada pemasok yang tersedia',
  here_add_supplier: 'Tambahkan pemasok baru di sini',
  add_supplier: 'Tambahkan pemasok',
  plz_input_supplier_num: 'Silakan masukkan nomor pemasok',
  add_suc: 'Berhasil menambahkan',

  new_products_launched: 'Produk baru diluncurkan',
  profit_txt: 'Keuntungan',
  has_been_added: 'Sudah ada di rak',
  on_display: 'Bisa ditaruh di rak',
  coming_soon_txt: 'Tersedia sekarang',
  sold_txt: 'Terjual',
  removed_txt: 'Dihapus',

  order_detail_txt: 'Detail pesanan',
  waiting_express_delivery: 'Menunggu pengiriman ekspres',
  customer_info: 'Informasi pelanggan',
  order_good: 'Pesan Barang',
  order_num: 'Nomor pesanan',
  order_date: 'tanggal pemesanan',
  order_content: 'Ringkasan Pesanan',
  purchase_price_txt: 'Harga pembelian',
  income_txt: 'Pendapatan',

  order_txt: 'Pesan',
  pending_payment: 'pembayaran tertunda',
  ready_for_shipment: 'Akan dikirim',
  shipped_txt: 'dikirim',
  paid_txt: 'Terkirim',
  good_num: 'nomor barang',
  pay_and_ship: 'Pembayaran dan Pengiriman',
  pay_suc: 'Pembayaran berhasil',
  no_order: 'Belum ada pesanan',
  no_order_tips: 'Anda dapat melacak pesanan dan pengembalian Anda di sini',
  suer_order: 'Konfirmasi pembayaran',
  pay_way: 'metode pembayaran',
  wallet_pay: 'pembayaran dompet',
  sure_pay: 'Konfirmasi pembayaran',
  paying_txt: 'Membayar...',

  manage_showcase: 'Kelola etalase',
  add_new_good: 'Tambahkan produk baru',
  heating_txt: 'Pemanasan',
  promotion: 'promosi',
  promotion_tip: 'Tips promosi',
  promotion_tip_txt:
    'Poin Anda saat ini kurang dari 200 dan Anda tidak dapat membayar produk Anda',
  copy_link: 'Salin tautan',
  scan_qr_code: 'Pindai kode QR',

  about_good: 'Tentang produk',
  specification: 'spesifikasi',
  product_overview: 'Ikhtisar Produk',

  top_tips_1: 'Transformasi toko Douyin online',
  top_tips_2: 'Saat ini hanya mendukung undangan internal',

  certification_activation_code: 'kode undangan',
  plz_write_invite_code: 'Silakan masukkan kode undangan',
  active_user_email: 'email',
  plz_input_active_user_email: 'Silakan masukkan email Anda',
  active_user_email_code: 'Kode verifikasi email',
  plz_input_active_user_email_code: 'Silakan masukkan kode verifikasi email',
  active_get_email_code_txt: 'Dapatkan kode verifikasi',
  active_user_name: 'nama',
  plz_input_active_user_name: 'Silakan masukkan nama Anda',
  active_user_id: 'Nomor KTP/paspor',
  plz_input_active_user_id: 'Silakan masukkan nomor KTP/paspor Anda',
  upload_active_user_id: 'Unggahan sertifikat',
  plz_upload_user_id_img: 'Silakan upload foto halaman depan KTP/paspor Anda',

  promotion_01: 'Promosi',
  promotion_02: 'Jumlah promosi',
  promotion_03: 'Silakan masukkan jumlah promosi',
  promotion_04: 'Konfirmasi promosi',
  promotion_05: 'Promosi berhasil',

  bao_txt: 'Tingkat paparan',
  bal_no: 'Saldo tidak mencukupi',

  _yqm_01: 'Silakan isi kode aktivasi yang benar',

  all_orders: 'Semua pesanan',
  mine_orders: 'Pesanan saya',
  mine_services: 'Layanan saya',

  _in_sp: 'Peringkat produk',
  _r_k01: 'Peringkat',
  _r_k02: 'Komoditas',
  _r_k03: 'panas',

  _c_201: 'Nama domain autentikasi tidak ada',
  _c_202: 'Pengguna telah diautentikasi dan diaktifkan',
  _c_204: 'Kode aktivasi telah digunakan',

  gl_visits: 'Kunjungan',

  hl_agreement: 'setuju',
  hl_service_agreement: 'Perjanjian Layanan',
  hl_selected_agreement: 'Silakan periksa perjanjian layanan',

  service_agreement_content_1:
    '1. Pilih negara dan wilayah yang sesuai: Pertama, Anda perlu menentukan negara atau wilayah tertentu tempat Anda ingin membuka toko Douyin. Hal ini mengharuskan Anda memiliki pemahaman dan analisis tertentu tentang pasar konsumen, latar belakang budaya, dan persaingan di kawasan tersebut untuk memberikan dasar bagi penentuan posisi toko dan strategi bisnis Anda. ',
  service_agreement_content_2:
    '2. Daftarkan akun Douyin: Setelah menentukan negara atau wilayah target, Anda perlu mendaftarkan akun Douyin milik wilayah tersebut. Anda dapat mendaftar melalui jalur resmi, atau Anda dapat mencari perantara lokal untuk membantu menyelesaikan proses pendaftaran. ',
  service_agreement_content_3:
    '3. Siapkan informasi: Saat mendaftarkan akun, Anda perlu menyiapkan beberapa informasi yang relevan. Dokumen sertifikasi informasi kartu ID pribadi dapat memungkinkan platform Douyin untuk mengonfirmasi identitas sah Anda, deposit pembukaan toko $500, dan memastikan bahwa toko Anda mematuhi yang relevan hukum, persyaratan peraturan. ',
  service_agreement_content_4:
    '4. Pilih model bisnis: Saat membuka toko Douyin, Anda dapat memilih model yang dioperasikan sendiri atau model entri pihak ketiga. Model yang dioperasikan sendiri berarti pedagang secara langsung mengoperasikan dan mengelola tokonya sendiri, sedangkan model entri pihak ketiga berarti toko tersebut diserahkan kepada platform pihak ketiga untuk dioperasikan. Berdasarkan situasi aktual dan keunggulan sumber daya, Anda dapat memilih model bisnis yang paling sesuai untuk Anda. ',
  service_agreement_content_5:
    '5. Persiapan dan promosi produk: Sebelum membuka toko, Anda perlu menyiapkan inventaris produk yang cukup dan melakukan pemasaran yang efektif. Anda dapat mempromosikannya melalui metode online dan offline, seperti membeli paket lalu lintas untuk meningkatkan eksposur toko Anda dan menarik lebih banyak pengguna untuk memperhatikan dan membeli. ',
  service_agreement_content_6:
    '6. Membangun sistem layanan pelanggan: Untuk memberikan pengalaman pengguna yang lebih baik, Anda perlu membangun sistem layanan pelanggan yang lengkap. Termasuk segera menanggapi pertanyaan dan keluhan pengguna, menangani masalah purna jual, dll. Hal ini dapat meningkatkan kepercayaan pengguna terhadap toko Anda serta meningkatkan kepuasan dan loyalitas pelanggan. ',
}
